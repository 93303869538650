<template>
    <div class="ant-card" id="course_category">
        <a-spin v-if="$route.meta.isShow" :spinning="spinning" size="large">
            <div class="ant-card-body">
                <div class="head-box">
                    <div class="table-operator">
                        <a-button icon="plus" type="primary" @click="onAdd" v-show="isShowAdd">新建banner</a-button>
                    </div>
                </div>
                <div class="contant-box">
                    <a-table ref="table" :columns="columns" :data-source="tableList" :row-key="record => record.id" :loading="tableLoading" :pagination="false">
                        <span slot="number" slot-scope="number, record, index">
                          <span>{{ index + 1 }}</span>
                        </span>
                        <img v-viewer class="avatar" slot="imageUrl" slot-scope="imageUrl, record" :src="record.imageUrl" alt="头像"/>
                        <span slot="putawayFlagText" slot-scope="putawayFlagText, record">
                            <a-badge v-show="record.putawayFlag == 0" status="error" text="已下架"/>
                            <a-badge v-show="record.putawayFlag == 1" status="processing" text="定时上架"/>
                            <a-badge v-show="record.putawayFlag == 2" status="success" text="已上架"/>
                            <a-badge v-show="record.putawayFlag == 3" status="warning" text="暂不上架"/>
                        </span>
                        <span slot="action" slot-scope="text, record">
                            <template>
                                <a class="margin_right20" v-show="record.putawayFlag == 2 && isShowStatus" @click="releaseStatusConfirm(record)">取消发布</a>
                                <a class="margin_right20" v-show="record.putawayFlag == 0 && isShowStatus" @click="releaseStatusConfirm(record)">发布</a>
                                <a v-show="isShowUpd" class="btn-edit" @click="onEdit(record)">编辑</a>
                                <a v-show="isShowDel" class="btn-delete" @click="onDel(record)">删除</a>
                            </template>
                        </span>
                    </a-table>
                </div>
            </div>
            <!-- 分页功能 -->
            <MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn" v-show="!tableLoading"/>
        </a-spin>
        <router-view/>
    </div>
</template>

<script>
import MyPagination from "@/components/pagination/MyPagination";
import {Modal, message} from "ant-design-vue";
import {codeFn} from "@/utils/tools";
import {
    GetBannerApi,
    updateBannerStatusApi,
    delBannerApi,
} from "@/request/api/indexManage";

export default {
    components: {MyPagination},
    created() {
        if (codeFn("/adminv2/saveBanner/add")) this.isShowAdd = true;
        if (codeFn("/adminv2/saveBanner/edit")) this.isShowUpd = true;
        if (codeFn("/adminv2/delBanner")) this.isShowDel = true;
        if (codeFn("/adminv2/updateBannerStatus")) this.isShowStatus = true;
    },
    data() {
        return {
            spinning: false, // 全局loading
            columns: [
                {
                    title: "序号",
                    dataIndex: "number",
                    key: "number",
                    width: "5%",
                    scopedSlots: {customRender: "number"},
                },
                {
                    title: "banner图片",
                    dataIndex: "imageUrl",
                    key: "imageUrl",
                    width: "20%",
                    scopedSlots: {customRender: "imageUrl"},
                },
                {
                    title: "所属分类",
                    dataIndex: "cateName",
                    key: "cateName",
                    width: '30%',
                    scopedSlots: {customRender: "cateName"},
                },
                {
                    title: "状态",
                    dataIndex: "putawayFlagText",
                    key: "putawayFlagText",
                    width: '10%',
                    scopedSlots: {customRender: "putawayFlagText"},
                },
                {
                    title: "创建时间",
                    dataIndex: "createTime",
                    key: "createTime",
                    width: '15%',
                },
                {
                    title: "操作",
                    dataIndex: "action",
                    key: "action",
                    width: "20%",
                    scopedSlots: {customRender: "action"},
                },
            ],
            tableList: [],
            tableLoading: false,
            confirmLoading: false,
            isShowAdd: false, // 修改是否隐藏
            isShowUpd: false, // 新增是否隐藏
            isShowDel: false, // 删除是否隐藏
            isShowStatus: false, // 上下架是否隐藏
            pageNo: 1, // 当前页
            pageSize: 20, // 每页条数
            count: 0// 列表的总条数
        };
    },
    mounted() {
        this.getTagList();
    },
    methods: {
        // 分页功能切换的回调
        showSizeChangeFn(current, pageSize) {
            this.pageNo = current;
            this.pageSize = pageSize;
            this.getTagList();
        },
        // 列表数据
        getTagList() {
            this.tableLoading = true;
            GetBannerApi({
                pageNo: this.pageNo,
                pageSize: this.pageSize
            }).then((res) => {
                if (res.code === 200) {
                    this.tableList = res.data.data;
                    this.count = res.data.count;
                }
                this.tableLoading = false;
            });
        },
        // 发布/取消发布弹窗
        releaseStatusConfirm(record) {
            const text = record.releaseStatus === 1 ? '取消发布' : '发布';
            Modal.confirm({
                title: "提示",
                content: `确认${text}吗`,
                okText: "确认",
                okType: "danger",
                cancelText: "取消",
                onOk: () => {
                    this.updateCateStatus(record);
                }
            });
        },
        updateCateStatus(record) {
            // 发布/取消发布状态变更
            updateBannerStatusApi({
                id: record.id
            }).then(({code, msg}) => {
                if (code === 200) {
                    this.getTagList();
                } else {
                    message.error(msg || "操作失败，请稍后重试");
                }
            });
        },
        onAdd() {
            this.$router.push("/indexManage/IndexBannerList/addBanner/0");
        },
        onEdit(record) {
            this.$router.push(`/indexManage/IndexBannerList/addBanner/${record.id}`);
        },
        onDel(record) {
            let that = this;
            if (record.putawayFlag == 2) {
                message.error("请先下线banner图后再删除");
            } else {
                Modal.confirm({
                    title: "提示",
                    content: "是否确认删除banner图",
                    okText: "确认",
                    okType: "danger",
                    cancelText: "取消",
                    onOk() {
                        delBannerApi({id: record.id}).then(({code, msg}) => {
                            if (code === 200) {
                                message.success("删除成功", 3);
                                that.getTagList();
                            } else {
                                message.error(msg || "操作失败，请稍后重试");
                            }
                        })
                    }
                });
            }
            // 删除

        },
        // 回到顶部的函数
        targetFn() {
            return document.querySelector("#course");
        },
    }
};
</script>

<style lang="less" scoped>
#course_category {
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
}

.head-box {
    margin-bottom: 30px;
}

.avatar {
    width: 160px;
    height: 80px;
    object-fit: contain;
}

.btn-edit {
    margin-right: 40px;
}
</style>
